
import { getMetaTags } from '@/utils'

export default {
  layout: 'promo',
  head() {
    const title = this.$t('page_promo_title')
    const description = this.$t('page_promo_seo_description')
    const pageUrl = ''
    const settings = {
      domain: {
        title: 'Koppa',
        og_image_url: '',
        social_url_twitter: ''
      }
    }

    return getMetaTags(title, description, pageUrl, settings)
  }
}
